import React, { useCallback, useState, forwardRef, useContext } from 'react';
import styled from 'styled-components';
import type { MenuProps } from 'antd';
import { Menu, Modal, Select } from 'antd';
import { CloseOutlined, ContactsOutlined, PlusOutlined, SignatureFilled } from '@ant-design/icons';
import { FormValuesExtended, Persona } from '../../types';
import PersonaStyle from '../Persona/PersonaStyle';
import { FormContext } from '../../contexts/FormContext';

const { Option } = Select;

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    label: 'My style',
    key: 'mystyle',
    icon: <SignatureFilled />,
  },
  // {
  //   label: 'Linkedin Profile',
  //   key: 'linkedin_profile',
  //   icon: <LinkedinFilled />,
  // },
  {
    label: 'Persona',
    key: 'persona',
    icon: <ContactsOutlined />,
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

const Choose = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  height: 77px;
  cursor: pointer;
  transition: background 0.1s ease;
  border: 1px solid #e4e2df;

  &:hover {
    background: #fafafa;
  }
`;

const PersonaContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  align-items: center;

  cursor: pointer;

  transition: background 0.1s ease;
  border: 1px solid #e4e2df;

  &:hover{
      background: #fafafa;
  }
`;

const CloseComponent = styled(CloseOutlined)`
  transition: color 0.3s;
  cursor: pointer;
  &:hover {
    color: #E64D4D;
  }
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const ItemTitle = styled.h4`
  margin: 0;
  color: #333;
  font-size: 16px;
`;

// const ItemDescription = styled.p`
//   margin: 0;
//   color: #666;
//   font-size: 14px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 3;
//   -webkit-box-orient: vertical;
// `;


interface StyleProps {
  onChange: (changedValues: Partial<FormValuesExtended>) => void;
  ref?: React.Ref<HTMLDivElement>;
}

const StyleComponent = forwardRef<HTMLDivElement, StyleProps>((props, ref) => {
  // const [linkedinProfile, setLinkedinProfile] = useState('');
  const [openPersona, setOpenPersona] = useState(false);
  const { formValues, setFormValues } = useContext(FormContext)!;
  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(formValues.persona);
  const [current, setCurrent] = useState(formValues.style);

  const handleMenuChange: MenuProps['onClick'] = useCallback((e: { key: string }) => {
    setCurrent(e.key);
    props.onChange({ style: e.key });
  }, [props.onChange]);

  const handleToneChange = useCallback((value: string) => {
    props.onChange({ tone: value });
  }, [props.onChange]);

  const handlePronounChange = useCallback((value: string) => {
    props.onChange({ pronoun: value });
  }, [props.onChange]);

  const handleLengthChange = useCallback((value: string) => {
    props.onChange({ length: value });
  }, [props.onChange]);

  const handleLanguageChange = useCallback((value: string) => {
    props.onChange({ language: value });
  }, [props.onChange]);

  // const handleLinkedinProfileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
  //   setLinkedinProfile(e.target.value);
  //   props.onChange({ linkedinProfile: e.target.value });
  // }, [props.onChange]);

  const onSelectPersonaFunc = (perso: Persona) => {
    setSelectedPersona(perso)
    setOpenPersona(false)
    props.onChange({ personaId: perso.id!, persona: perso });
  }

  const onRemovePersona = () => {
    setSelectedPersona(null)
    props.onChange({ personaId: null, persona: null });
  }

  return (
    <Container ref={ref}>
      <Modal
        title="Persona"
        centered
        open={openPersona}
        onOk={() => setOpenPersona(false)}
        onCancel={() => setOpenPersona(false)}
        width={500}
        footer={null}
      >
        <PersonaStyle onSelectPersonaFunc={onSelectPersonaFunc} />
      </Modal>
      <Menu onClick={handleMenuChange} selectedKeys={[current]} mode="horizontal" items={items} />

      {current === 'mystyle' ? (
        <>
          <Select placeholder="Select the tone" onChange={handleToneChange} value={formValues.tone || undefined}>
            <Option value="serious">Serious</Option>
            <Option value="humorous">Humorous</Option>
            <Option value="informative">Informative</Option>
            <Option value="inspirational">Inspirational</Option>
          </Select>
          {/* <Select placeholder="Select a pronoun" onChange={handlePronounChange}>
            <Option value="I">I</Option>
            <Option value="you">You</Option>
            <Option value="we">We</Option>
            <Option value="they">They</Option>
          </Select> */}
          <Select placeholder="select the length" onChange={handleLengthChange} value={formValues.length || undefined}>
            <Option value="short">Short</Option>
            <Option value="medium">Medium</Option>
            <Option value="long">Long</Option>
          </Select>
          <Select placeholder="select the language" onChange={handleLanguageChange} value={formValues.language || undefined}>
            <Option value="english">English</Option>
            <Option value="french">French</Option>
            <Option value="spanish">Spanish</Option>
            <Option value="german">German</Option>
            <Option value="italian">Italian</Option>
            <Option value="portuguese">Portuguese</Option>
            <Option value="chinese">Chinese</Option>
            <Option value="japanese">Japanese</Option>
            <Option value="korean">Korean</Option>
            <Option value="russian">Russian</Option>
            <Option value="arabic">Arabic</Option>
            <Option value="hindi">Hindi</Option>
            <Option value="bengali">Bengali</Option>
            <Option value="urdu">Urdu</Option>
            <Option value="swahili">Swahili</Option>
            <Option value="turkish">Turkish</Option>
            <Option value="vietnamese">Vietnamese</Option>
            <Option value="thai">Thai</Option>
            <Option value="dutch">Dutch</Option>
            <Option value="greek">Greek</Option>
            <Option value="polish">Polish</Option>
            <Option value="swedish">Swedish</Option>
            <Option value="norwegian">Norwegian</Option>
            <Option value="finnish">Finnish</Option>
            <Option value="danish">Danish</Option>
            <Option value="hebrew">Hebrew</Option>
          </Select>
        </>
      ) : (
        <>
          {selectedPersona ? (
            <PersonaContainer>
              <ItemDetails>
                <ItemTitle>
                  {selectedPersona.name}
                </ItemTitle>
              </ItemDetails>
              <CloseComponent onClick={onRemovePersona} />
            </PersonaContainer>
          ) : (
            <Choose onClick={() => setOpenPersona(true)}><PlusOutlined /></Choose>
          )}
        </>
      )}

    </Container>
  );
});

export default StyleComponent;

