import React, { useState, useEffect, ChangeEvent } from 'react';
import { Modal, Input, Select, Tabs, Slider, Typography, List, Button } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Product } from '../../types';

const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;

interface ProductModalProps {
  visible: boolean;
  onCreateOrEdit: (product: Product) => void;
  product: Product | null;
  onCancel: () => void;
}

const FormItem = styled.div`
  margin-bottom: 20px;
  position: relative;
`;


const Label = styled.label`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 12px;
  color: #888;
`;

const initialFormState: Product = {
  id: undefined,
  title: '',
  description: '',
};

const ProductModal: React.FC<ProductModalProps> = ({ visible, onCreateOrEdit, product, onCancel }) => {
  const [form, setForm] = useState<Product>(initialFormState);

  useEffect(() => {
    if (product) {
      setForm(product);
    } else {
      setForm(initialFormState);
    }
  }, [product]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSelectChange = (value: any, field: keyof Product) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleNumberChange = (value: number | null, field: keyof Product) => {
    if (value !== null) {
      setForm({
        ...form,
        [field]: value,
      });
    }
  };



  const handleSubmit = () => {
    // Validation du formulaire si nécessaire
    onCreateOrEdit(form);
  };

  return (
    <Modal
      title={product ? 'Edit Product' : 'Create a new Product'}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      width={800}
    >
      <Paragraph>
        Use this form to add information about what you offer. This could be:
        <ul>
          <li>A software or SaaS (Software as a Service)</li>
          <li>A physical product you sell</li>
          <li>A service you provide</li>
          <li>A training course or program</li>
        </ul>
      </Paragraph>
      <div style={{ marginBottom: '20px' }} />
      <FormItem>
        {form.title && <Label>Product or Service Title</Label>}
        <Input
          name="title"
          value={form.title}
          onChange={handleInputChange}
          placeholder="Product or Service Title (e.g., 'Digital Marketing Training')"
          maxLength={200}
        />
      </FormItem>
      <FormItem>
        {form.description && <Label>Description</Label>}
        <Input.TextArea
          name="description"
          value={form.description}
          onChange={handleInputChange}
          placeholder="Describe your product or service (e.g., 'This training covers the basics of digital marketing, perfect for beginners.')"
          autoSize={{ minRows: 3, maxRows: 6 }}
          rows={4}
          maxLength={8000}
        />
      </FormItem>
    </Modal>
  );
};

export default ProductModal;
