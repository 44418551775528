import React, { useState } from 'react';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

const ListItemContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: background 0.1s ease;
  border: 1px solid #e4e2df;
  gap: 5px;

  &:hover {
    background: #fafafa;
  }
`;

const ItemImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ItemTitle = styled.h4`
  margin: 0;
  color: #333;
  font-size: 16px;
`;

const ItemDescription = styled.p`
  margin: 0;
  color: #666;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const CloseComponent = styled(CloseOutlined)`
  transition: color 0.3s;
  cursor: pointer;
  &:hover {
    color: #E64D4D;
  }
`;

type ArticleType = {
  title: string;
  description: string;
  imageUrl: string;
};

type ListItemProps = {
  article: ArticleType;
  onClose: () => void;
};

export const ArticleCard: React.FC<ListItemProps> = ({ article, onClose }) => {
  const [isImageError, setIsImageError] = useState(false);

  return (
    <ListItemContainer onClick={onClose}>
      {!isImageError && article.imageUrl && (
        <ItemImage
          src={article.imageUrl}
          alt=""
          onError={() => setIsImageError(true)}
        />
      )}
      <ItemDetails>
        <ItemTitle>{article.title}</ItemTitle>
        <ItemDescription>{article.description}</ItemDescription>
      </ItemDetails>
      <CloseComponent onClick={onClose} />
    </ListItemContainer>
  );
};
