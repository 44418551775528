import React from 'react';
import styled from 'styled-components';
import { ArticleType } from '../../types';
import { ExportOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

// Styled components for the list and list items
const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    width: 100%;
    padding-right: 10px;
    height: 400px;

    &::-webkit-scrollbar {
        height: 7px;
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 5px;
        background: rgba(200, 200, 200, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: #AAA;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #888;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }
`;

const ListItemContainer = styled.div<{ selected: boolean }>`
    display: flex;
    padding: 10px;
    border-radius: 8px;
    background-color: ${(props) => (props.selected ? '#fff' : '#eee')};
    align-items: center;

    cursor: pointer;

    transition: background 0.1s ease;

    &:hover {
        background: ${(props) => (props.selected ? '#eee' : '#d5d5d5')};
    }
`;

const ImageContainer = styled.div`
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #ccc;
    font-size: 24px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    margin-right: 10px; // Space between the image and the text
`;

const ItemImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
`;

const ItemDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
`;

const ItemTitle = styled.h4`
    margin: 0;
    color: #333;
    font-size: 16px;
`;

const ItemDescription = styled.p`
    margin: 0;
    color: #666;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

const ReadMoreButton = styled.a`
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    // background: linear-gradient(135deg, #007bff, #0056b3);
    text-decoration: none;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    transition: all 0.3s ease;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;

    &:hover {
        // background: linear-gradient(135deg, #0056b3, #003d80);
    }
`;

// Props type definitions

type ListItemProps = {
  article: ArticleType;
  selectedArticle: boolean;
  onAction: () => void;
};

type ListProps = {
  articles: ArticleType[];
  selectedArticle: ArticleType | null
  containerHeight: number;
  onAction: (article: ArticleType | null) => void;
};

// ListItem component
const ListItem: React.FC<ListItemProps> = ({ article, selectedArticle, onAction }) => {

  return (
    <ListItemContainer selected={selectedArticle} onClick={onAction}>
      <ImageContainer>
        {article.title.charAt(0)}
        <ItemImage src={article.imageUrl} alt="" onError={(e) => (e.currentTarget.style.display = 'none')} />
      </ImageContainer>
      <ItemDetails>
        <ItemTitle>{article.title}</ItemTitle>
        <ItemDescription>{article.description}</ItemDescription>
      </ItemDetails>
      <Tooltip title="Opens the article in a new tab">
        <ReadMoreButton href={article.url} target="_blank" rel="noopener noreferrer">
          <ExportOutlined />
        </ReadMoreButton>
      </Tooltip>
    </ListItemContainer>
  );
};

// List component
export const List: React.FC<ListProps> = ({ articles, containerHeight, onAction, selectedArticle }) => (
  <ListContainer style={{ height: `${containerHeight}px` }}>
    {articles.length > 0 ? (
      articles.map((article, index) => (
        <ListItem key={index} article={article} selectedArticle={selectedArticle?.url != article.url} onAction={() => {
          onAction(selectedArticle?.url != article.url ? article : null);
        }} />
      ))
    ) : (
      <div>No articles to display. Select themes to see the list of articles.</div>
    )}
  </ListContainer>
);