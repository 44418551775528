import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

interface SelectOptionsProps {
  value: string;
  label: string;
}

interface SelectProps {
  value?: string;
  onChange?: (value: any) => void;
  placeholder?: string;
  errorMessage?: string | undefined;
  onError?: boolean | undefined;
  setOnError?: (value: boolean) => void;
  resetInput?: boolean | undefined;
  options: SelectOptionsProps[];
  style?: React.CSSProperties;
}

const SelectOption: React.FC<SelectProps> = ({
                                               value,
                                               onChange,
                                               placeholder,
                                               errorMessage,
                                               onError,
                                               setOnError,
                                               resetInput,
                                               options,
                                               style,
                                             }) => {
  
  const [touched, setTouched] = useState(false);
  
  useEffect(() => {
    setTouched(false);
    if (setOnError) {
      setOnError(false);
    }
  }, [resetInput]);
  
  useEffect(() => {
    if (setOnError) {
      if (touched && value === undefined) {
        setOnError(true);
      } else {
        setOnError(false);
      }
    }
  }, [touched, value]);
  
  return (
    <div>
      <Select
        allowClear={true}
        value={value}
        style={style}
        onChange={onChange}
        onBlur={() => setTouched(true)}
        placeholder={placeholder}
        status={onError ? 'error' : ''}>
        {options.map((opt) => (
          <Option key={opt.value} value={opt.value}>
            {opt.label}
          </Option>
        ))}
      </Select>
      {onError &&
        <div style={{
          color: 'red',
          marginTop: 4,
          paddingLeft: '10px',
        }}>{errorMessage ? errorMessage : 'This field is required'}</div>}
    </div>
  );
};

export default SelectOption;