import React, { forwardRef } from 'react';
import styled from 'styled-components';
import LinkedinIcon from '../../assets/linkedin_logo.png';

// Interface pour les props du composant
interface LinkedinLoginProps {
  text?: string;
  disabled?: boolean;
  onClick: () => void;
  ref?: React.Ref<HTMLDivElement>;
}

const LinkedinContainer = styled.button<{ disabled: boolean }>`
    padding: 0 16px;
    cursor: pointer;
    background-color: ${({ disabled }) => (disabled ? '#0288d1a6' : '#0288D1')};
    border-radius: 10px;
    border: 0;
    font-size: 13.333px;
    font-weight: 600;
    line-height: normal;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 46px;

    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0288d1a6;
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : '')};
    }
`;

const Input = styled.span`
    padding: 16px 0;
    font-size: 13.333px;
    font-weight: 600;
    line-height: normal;
`;

const LinkedinButton = forwardRef<HTMLDivElement, LinkedinLoginProps>((props, ref) => {
  const { text, onClick, disabled = false } = props;
  return (
    <LinkedinContainer onClick={onClick} disabled={disabled}>
      <img src={LinkedinIcon} alt="Sign in with Linked In" width={33} />
      <Input>
        {text}
      </Input>
    </LinkedinContainer>
  );
});

export default LinkedinButton;
