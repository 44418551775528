// src/contexts/FormContext.tsx
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { FormValues, FormValuesExtended } from '../types'; // Assurez-vous que ce type est bien exporté

// Définition du type du contexte
interface FormContextType {
  formValues: FormValuesExtended;
  setFormValues: React.Dispatch<React.SetStateAction<FormValuesExtended>>;
  contentPost: string;
  setContentPost: React.Dispatch<React.SetStateAction<string>>;
  extractFormValues: (values: FormValuesExtended) => FormValues;
  templatesPipe: any | null;
  setTemplatesPipe: React.Dispatch<React.SetStateAction<any | null>>;
}

// Création du contexte avec une valeur par défaut indéfinie
export const FormContext = createContext<FormContextType | undefined>(undefined);

// Définition des props du Provider
interface FormProviderProps {
  children: ReactNode;
}

// Création du Provider
export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [templatesPipe, setTemplatesPipe] = useState<any | null>(null);
  const [formValues, setFormValues] = useState<FormValuesExtended>({
    source: 'idea',
    topic: '',
    points: '',
    article: '',
    transcription: '',
    urlContent: '',
    style: 'mystyle',
    tone: '',
    pronoun: '',
    length: '',
    language: '',
    linkedinProfile: '',
    personaId: null,
    productDescription: '',
    
    articleObject: null,
    persona: null,
    video: null,
    url: null,
    product: null,
    linkToProduct: false,
  });
  
  useEffect(() => {
    console.log(templatesPipe);
  }, [templatesPipe]);
  
  const [contentPost, setContentPost] = useState<string>('');
  
  const extractFormValues = (values: FormValuesExtended): FormValues => {
    const {
      source,
      topic,
      points,
      article,
      transcription,
      urlContent,
      style,
      tone,
      pronoun,
      linkedinProfile,
      length,
      language,
      personaId,
      productDescription,
    } = values;
    return {
      source,
      topic,
      points,
      article,
      transcription,
      urlContent,
      style,
      tone,
      pronoun,
      linkedinProfile,
      length,
      language,
      personaId,
      productDescription,
    };
  };
  
  return (
    <FormContext.Provider value={{
      formValues,
      setFormValues,
      contentPost,
      setContentPost,
      extractFormValues,
      templatesPipe,
      setTemplatesPipe,
    }}>
      {children}
    </FormContext.Provider>
  );
};
