import React, { useContext } from 'react';
import styled from 'styled-components';
import { DeleteOutlined, SaveOutlined, SendOutlined } from '@ant-design/icons';
import { AuthContext } from '../../auth/AuthContext';
import { Tooltip } from 'antd';
import user from '../../assets/basic_user.png';

const PreviewHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 68px;
    gap: 10px;
`;

const User = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
`;

const FirstRow = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: 400;
`;

const Bold = styled.div`
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
`;

const SecondRow = styled.div`
    font-weight: 400;
    font-size: 12px;
`;

const ThirdRow = styled.div`
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
`;

const StyledSvg = styled.svg`
    margin-right: 5px;
    vertical-align: middle;
`;

const More = styled.div`
    display: flex;
    gap: 8px;
`;

// Style for the round buttons
const IconButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    background-color: #fff;
    border: 1px solid #e4e2df;
    cursor: pointer;

    &:hover {
        background-color: #e0e0e0;
    }
`;

const RemoveButton = styled(IconButton)`
    &:hover {
        background-color: #ed2c2c;
        color: #fff;
        border: 1px solid #fff;
    }
`;

const SaveButton = styled(IconButton)`
    &:hover {
        background-color: #6be363;
        color: #fff;
        border: 1px solid #fff;
    }
`;

const SendButton = styled(IconButton)`
    &:hover {
        background-color: #0288D1;
        color: #fff;
        border: 1px solid #fff;
    }
`;

interface HeaderProps {
  onDelete: () => void;
  onSave: () => void;
  onSend: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onDelete, onSave, onSend }) => {
  const { authUser } = useContext(AuthContext);
  
  return (
    <PreviewHeader>
      {authUser && authUser.profileImage ? (
        <User src={authUser.profileImage} alt="user" />
      ) : (
        <User src={user} alt="default user" />
      )}
      <Text>
        <FirstRow>
          <Bold>{authUser?.linkedin_name ? authUser.linkedin_name : `${authUser.firstname} ${authUser.lastname}`} •</Bold>&nbsp;You
        </FirstRow>
        <SecondRow>
          Top Voice LinkedIn
        </SecondRow>
        <ThirdRow>
          3w • &nbsp;<StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" data-supported-dps="16x16"
                                fill="currentColor" width="16" height="16" focusable="false">
          <path
            d="M8 1a7 7 0 107 7 7 7 0 00-7-7zM3 8a5 5 0 011-3l.55.55A1.5 1.5 0 015 6.62v1.07a.75.75 0 00.22.53l.56.56a.75.75 0 00.53.22H7v.69a.75.75 0 00.22.53l.56.56a.75.75 0 01.22.53V13a5 5 0 01-5-5zm6.24 4.83l2-2.46a.75.75 0 00.09-.8l-.58-1.16A.76.76 0 0010 8H7v-.19a.51.51 0 01.28-.45l.38-.19a.74.74 0 01.68 0L9 7.5l.38-.7a1 1 0 00.12-.48v-.85a.78.78 0 01.21-.53l1.07-1.09a5 5 0 01-1.54 9z"></path>
        </StyledSvg>
        </ThirdRow>
      </Text>
      <More>
        <Tooltip placement="top" title="Delete scheduled post" arrow={true} mouseEnterDelay={0.2}>
          <RemoveButton onClick={onDelete}>
            <DeleteOutlined />
          </RemoveButton>
        </Tooltip>
        <Tooltip placement="top" title="Save scheduled post" arrow={true} mouseEnterDelay={0.2}>
          <SaveButton>
            <SaveOutlined onClick={onSave} />
          </SaveButton>
        </Tooltip>
        <Tooltip placement="top" title="Post now on Linkedin" arrow={true} mouseEnterDelay={0.2}>
          <SendButton onClick={onSend}>
            <SendOutlined />
          </SendButton>
        </Tooltip>
      </More>
    </PreviewHeader>
  );
};
