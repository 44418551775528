import React, { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import type { MenuProps } from 'antd';
import { Checkbox, Collapse, Input, Menu, message, Modal, Tooltip } from 'antd';
import {
  BulbFilled,
  CloudFilled,
  FileTextFilled,
  InfoCircleOutlined,
  PlusOutlined,
  TagFilled,
  YoutubeFilled,
} from '@ant-design/icons';
import { ArticleType, FormValues, FormValuesExtended, Product, Video } from '../../types';
import { YoutubeComponent } from '../youtube/Youtube';
import { YoutubeCard } from '../youtube/YoutubeCard';
import { AuthContext } from '../../auth/AuthContext';
import { ArticlesComponent } from '../articles/Articles';
import { ArticleCard } from '../articles/ArticleCard';
import type { SearchProps } from 'antd/es/input/Search';
import CardPostpilot from '../cards/card';
import ProductSubject from '../product/productSubject';

const { Search } = Input;
const { TextArea } = Input;

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    label: 'Idea',
    key: 'idea',
    icon: <BulbFilled />,
  },
  {
    label: 'Article',
    key: 'article',
    icon: <FileTextFilled />,
  },
  {
    label: 'Video',
    key: 'video',
    icon: <YoutubeFilled />,
  },
  {
    label: 'URL',
    key: 'url',
    icon: <CloudFilled />,
  },
];

const Choose = styled.div`
    display: flex;
    padding: 10px;
    border-radius: 8px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    height: 77px;
    cursor: pointer;
    transition: background 0.1s ease;
    border: 1px solid #e4e2df;

    &:hover {
        background: #fafafa;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 20px;
`;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 10px;
`;

const Transcription = styled.div`
    max-height: 200px;
    width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        height: 7px;
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 5px;
        background: rgba(200, 200, 200, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: #AAA;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #888;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }
`;

const LabelText = styled.div`
    font-size: 12px;
`;

interface SubjectProps {
  onChange: (changedValues: Partial<FormValuesExtended>) => void;
  setLoading: (b: boolean) => void;
  setImage: (image: any) => void;
  initialSelectedVideo: Video | null;
  initialSelectedArticle: ArticleType | null;
  formValues: FormValuesExtended;
  ref?: React.Ref<HTMLDivElement>;
}

const SubjectComponent = forwardRef<HTMLDivElement, SubjectProps>((props, ref) => {

  const { onChange, setLoading, setImage, initialSelectedVideo, initialSelectedArticle, formValues } = props;

  const [current, setCurrent] = useState(formValues.source);
  const [url, setUrl] = useState(formValues.url || '');
  const [transcription, setTranscription] = useState(initialSelectedVideo ? initialSelectedVideo.description : '');
  const [contentUrl, setContentUrl] = useState(formValues.urlContent || '');
  const [openYoutube, setOpenYoutube] = useState(false);
  const [openArticle, setOpenArticle] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(initialSelectedVideo || formValues.video);
  const [selectedArticle, setSelectedArticle] = useState<ArticleType | null>(initialSelectedArticle || formValues.articleObject);
  const [selectedArticleModal, setSelectedArticleModal] = useState<ArticleType | null>(initialSelectedArticle|| formValues.articleObject);
  // const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  // const [linkToProduct, setLinkToProduct] = useState(false);
  const { myToken } = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();

  const onCheckboxChange = (e: any) => {
    // setLinkToProduct(e.target.checked);
    onChange({ linkToProduct: e.target.checked });
    if (!e.target.checked) {
      // setSelectedProduct(null); // Clear selected product when checkbox is unchecked
      onChange({ productDescription: '', product: null });
    }
  };

  const fetchContentFromURL = useCallback(async (url: string) => {
    try {
      const encodedUrl = encodeURIComponent(url); // Encodage pour éviter les conflits avec les caractères spéciaux

      const response = await fetch(`${process.env.REACT_APP_API_URL}/url-source/fetch-url?url=${encodedUrl}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${myToken}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch content');
      return response.text();
    } catch (error) {
      console.error('Error fetching content:', error);
      messageApi.open({
        type: 'error',
        content: 'Failed to fetch content from URL',
      });
      return null;
    }
  }, [messageApi, myToken]);

  const onSearch: SearchProps['onSearch'] = useCallback(async (value: string) => {
    setLoading(true);
    const content = await fetchContentFromURL(value);
    if (content) {
      const contentTruncated = content.substring(0, Math.min(content.length, 40000));
      setContentUrl(contentTruncated);
      onChange({ urlContent: contentTruncated, url: value }); // erreur ici
    }
    setLoading(false);
  }, [fetchContentFromURL, setLoading]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const encodedState = queryParams.get('state');
    if (encodedState) {
      try {
        const decodedState: FormValues = JSON.parse(decodeURIComponent(encodedState));
        setCurrent(decodedState['source']);
        setContentUrl(decodedState['urlContent']);
        if (decodedState['urlContent'] !== undefined) {
          if (onSearch) {
            onSearch(decodedState['urlContent']);
            setUrl(decodedState['urlContent']);
          }
        }
      } catch (error) {
        console.error('Erreur lors du décodage de l\'état : ', error);
      }
    }
  }, []);
  
  useEffect(() => {
    if (initialSelectedVideo) {
      setSelectedVideo(initialSelectedVideo);
      setCurrent('video');
    }
  }, [initialSelectedVideo]);

  useEffect(() => {
    if (initialSelectedArticle) {
      setSelectedArticle(initialSelectedArticle);
      setCurrent('article');
      setImage(initialSelectedArticle ? initialSelectedArticle.imageUrl : '');
    }
  }, [initialSelectedArticle]);

  const fetchContent = useCallback(async (videoId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/youtube/transcripts/${videoId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myToken}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch video transcription');
      const content_resp = await response.text();
      const contentTruncated = content_resp.substring(0, Math.min(content_resp.length, 40000));
      setTranscription(contentTruncated);
      onChange({ transcription: contentTruncated });
    } catch (error) {
      console.error('Error fetching video transcription:', error);
    }
  }, [myToken, onChange]);


  const handleMenuChange: MenuProps['onClick'] = (e: { key: string }) => {
    setCurrent(e.key);
    onChange({ source: e.key });
  };

  const extractVideoId = (url: string): string | null => {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    return match ? match[1] : null;
  };

  useEffect(() => {
    if (selectedVideo && selectedVideo.videoId) {
      fetchContent(selectedVideo.videoId);
    }
  }, [selectedVideo]);

  const handleTopicChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ topic: e.target.value });
  }, [onChange]);

  const handlePointsChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange({ points: e.target.value });
  }, [onChange]);

  const onselectVideo = useCallback((video: Video) => {
    setSelectedVideo(video);
    onChange({ video: video });
    setOpenYoutube(false);
  }, []);
  
  const onselectArticle = useCallback((article: ArticleType | null) => {
    if (!article) {
      return;
    }
    setSelectedArticle(article);
    setSelectedArticleModal(article);
    setImage(article.imageUrl);
    setOpenArticle(false);
  }, []);

  const onselectProduct = useCallback((product: Product) => {
    // setSelectedProduct(product);
    onChange({ productDescription: product.description, product: product });
    setOpenProduct(false);
  }, []);

  const ondeselectProduct = useCallback(() => {
    // setSelectedProduct(null);
    onChange({ productDescription: '', product: null });
  }, []);

  const ondeselectArticle = useCallback(() => {
    setSelectedArticle(null);
    setSelectedArticleModal(null);
    setImage(null);
    onChange({ article: '', articleObject: null });
  }, []);

  const ondeselectVideo = useCallback(() => { 
    setSelectedVideo(null);
    setTranscription("");
    onChange({ transcription: "", video: null });
  }, []);

  useEffect(() => {
    if (selectedArticle && selectedArticle.url) {
      onChange({ article: selectedArticle.content, articleObject: selectedArticle });
    }
  }, [selectedArticle]);

  return (
    <Container ref={ref}>
      {contextHolder}
      <Modal
        title="Video"
        centered
        open={openYoutube}
        onOk={() => setOpenYoutube(false)}
        onCancel={() => setOpenYoutube(false)}
        width={1000}
        footer={null}
      >
        <YoutubeComponent onAction={onselectVideo} />
      </Modal>

      <Modal
        title="Article"
        centered
        open={openArticle}
        okButtonProps={{ disabled: selectedArticleModal == null }}
        onOk={() => onselectArticle(selectedArticleModal)}
        onCancel={() => setOpenArticle(false)}
        width={1000}
      >
        <ArticlesComponent selectedArticle={selectedArticleModal} setSelectedArticle={setSelectedArticleModal} />
      </Modal>

      <Modal
        title="Product"
        centered
        open={openProduct}
        onOk={() => setOpenProduct(false)}
        onCancel={() => setOpenProduct(false)}
        width={500}
        footer={null}
      >
        <ProductSubject onSelectProductFunc={onselectProduct} />
      </Modal>

      <Menu onClick={handleMenuChange} selectedKeys={[current]} mode="horizontal" items={items} />
      {current === 'idea' && (
        <>
          <Input placeholder="What's the subject of your post? Enter a descriptive title..." value={formValues.topic} onChange={handleTopicChange} maxLength={200}
            suffix={
              <Tooltip title="Choose a topic in this field! You are free to add more information, such as the topics you want to cover, in the next field.">
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            } />
          <TextArea placeholder="Outline your key ideas here. Be clear and concise..." rows={4} value={formValues.points} onChange={handlePointsChange} maxLength={1000} />
        </>
      )}
      {current === 'article' && (
        <>
          {selectedArticle ? (
            <>
              <ArticleCard article={selectedArticle} onClose={() => ondeselectArticle()} />
              <Collapse items={[{
                key: '1',
                label: 'Article',
                children: <Transcription>{selectedArticle.content}</Transcription>,
              }]} />
            </>
          ) : (
            <Choose onClick={() => setOpenArticle(true)}><PlusOutlined /></Choose>
          )}
        </>
      )}
      {current === 'video' && (
        <>
          {selectedVideo ? (
            <>
              <YoutubeCard video={selectedVideo} onClose={() => ondeselectVideo()} />
              <Collapse items={[{
                key: '1',
                label: 'Transcription',
                children: <Transcription>{transcription}</Transcription>,
              }]} />
            </>
          ) : (
            <Choose onClick={() => setOpenYoutube(true)}><PlusOutlined /></Choose>
          )}
        </>
      )}
      {current === 'url' && (
        <>
          <LabelText>Supported URL types (website, YouTube video, news article/blog, LinkedIn post, ...)</LabelText>
          <Search placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            onSearch={onSearch} value={url} onChange={(e) => setUrl(e.target.value)} enterButton
            maxLength={1000} />
          {contentUrl !== '' ? (
            <Collapse items={[{ key: '1', label: 'Contenu', children: <Transcription>{contentUrl}</Transcription> }]} />
          ) : null}
        </>
      )}
      <Checkbox checked={formValues.linkToProduct} onChange={onCheckboxChange}>
        Do you want to link this subject to your product?
      </Checkbox>
      {formValues.linkToProduct && (
        <ProductContainer>
          <LabelText style={{ color: '#7d7d7d' }}><TagFilled /> Link to your product (optional)</LabelText>
          {formValues.product ? (
            <>
              <CardPostpilot title={formValues.product.title} onClose={ondeselectProduct} />
            </>
          ) : (
            <Choose style={{ height: 30, fontSize: 12, maxWidth: 200 }}
              onClick={() => setOpenProduct(true)}><PlusOutlined /></Choose>
          )}
        </ProductContainer>
      )}
    </Container>
  );
});

export default React.memo(SubjectComponent);
