// src/pages/TemplatePage.tsx
import React, { useRef, useState } from 'react';
import { Layout } from 'antd';
import Canvas from '../component/TemplateEditor/Canvas';
import LeftSider from '../component/TemplateEditor/LeftSider';
import SlideNavigator from '../component/TemplateEditor/SlideNavigator';
import styled from 'styled-components';
import TemplateHeader from '../component/TemplateEditor/Header/TemplateHeader';
import useTemplate from '../hooks/useTemplate';
import RightSidebar from '../component/TemplateEditor/RightSidebar';
import useSlides from '../hooks/useSlides';
import useShapes from '../hooks/useShapes';
import { ACTIONS } from '../component/TemplateEditor/constants';

const { Sider, Content } = Layout;

const AppLayout = styled(Layout)`
    height: 100%;
`;

const TemplatePage: React.FC = () => {
  const [switchMode, setSwitchMode] = useState<boolean>(true);
  const [action, setAction] = useState<string>(ACTIONS.SELECT);
  const fillColor = '#D9D9D9';
  const stageRef = useRef<any>(null);
  
  const {
    slides,
    setSlides,
    selectedSlideIndex,
    setSelectedSlideIndex,
    handleAddNewSlide,
    handleSlideSelect,
    handleRemoveSlide,
    handleReorderSlides,
  } = useSlides();
  
  const {
    selectedShape,
    setSelectedShape,
    selectedKonvaShape,
    setSelectedKonvaShape,
    transformerRef,
    handleNewShape,
    handleShapeChange,
    handleDeleteSelectedShape,
    handleShapeSelect,
    handleRenameShape,
    handleReorderShapes,
    handleImageUpload,
    executePendingDeletes,
  } = useShapes(slides, selectedSlideIndex, setSlides, action, setAction, stageRef);
  
  const {
    templates,
    currentTemplate,
    handleSave,
    handleDelete,
    handleNewTemplate,
    handleNameChange,
    handleMenuClick,
  } = useTemplate(
    setSlides,
    setSelectedSlideIndex,
    setSelectedShape,
    setSelectedKonvaShape,
    executePendingDeletes,
  );
  
  const handleSaveFunc = async (): Promise<{ status: boolean; message: string }> => {
    return handleSave(slides);
  };
  
  return (
    <AppLayout>
      <TemplateHeader
        action={action}
        setAction={setAction}
        onImageUpload={handleImageUpload}
        currentTemplate={currentTemplate}
        handleNameChange={handleNameChange}
        switchMode={switchMode}
        setSwitchMode={setSwitchMode}
        templates={templates}
        handleMenuClick={handleMenuClick}
        onSave={handleSaveFunc}
        onDelete={() => handleDelete()}
        onNewTemplate={handleNewTemplate}
        slides={slides}
      />
      <Layout>
        <Sider width={200} style={{ background: '#fff', borderLeft: '1px solid #ccc' }}>
          <LeftSider
            shapes={slides[selectedSlideIndex]}
            selectedShapeId={selectedShape?.id || null}
            onSelectShape={handleShapeSelect}
            onRenameShape={handleRenameShape}
            onReorderShapes={handleReorderShapes}
          />
        </Sider>
        <Content
          style={{
            padding: '0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            background: '#f2f2f2',
          }}
        >
          <div
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Canvas
              stageRef={stageRef}
              transformerRef={transformerRef}
              action={action}
              fillColor={fillColor}
              onSelectShape={handleShapeSelect}
              onNewShape={handleNewShape}
              shapes={slides[selectedSlideIndex]}
              setShapes={(newShapes: any) => {
                setSlides((prevSlides) => {
                  const newSlides = [...prevSlides];
                  newSlides[selectedSlideIndex] = newShapes;
                  return newSlides;
                });
              }}
            />
          </div>
          <SlideNavigator
            slides={slides}
            selectedSlideIndex={selectedSlideIndex}
            onAddNewSlide={handleAddNewSlide}
            onSelectSlide={handleSlideSelect}
            stageRef={stageRef}
            transformerRef={transformerRef}
            setSelectedShape={setSelectedShape}
            setSelectedKonvaShape={setSelectedKonvaShape}
            onRemoveSlide={handleRemoveSlide}
            onReorderSlides={handleReorderSlides}
          />
        </Content>
        <Sider width={300} style={{ background: '#fff' }}>
          <RightSidebar
            selectedShape={selectedShape}
            onUpdateShape={handleShapeChange}
            switchMode={switchMode}
          />
        </Sider>
      </Layout>
    </AppLayout>
  );
};

export default TemplatePage;
