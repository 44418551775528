import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariantEnum } from '../component/buttons/Button';
import { ProductModelApi } from '../model/SubscriptionModelApi';
import { message } from 'antd';
import { AuthContext } from '../auth/AuthContext';
import { SubscriptionService } from '../service/subscription.service';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100vh;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 600;
    color: #028CB5;
    margin-bottom: 0.5rem;
`;

const Description = styled.p`
    font-size: 1.125rem;
    color: #666666;
    margin-bottom: 0.5rem;
`;

const Divider = styled.div`
    height: 4px;
    width: 6rem;
    background-color: #fff;
    margin: 0.5rem auto 2rem;
    border-radius: 4px;
`;

const ToggleWrapper = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
`;

const ToggleButton = styled.button<{ active: boolean }>`
    background: ${({ active }) => (active ? '#028CB5' : '#E6F3F8')};
    color: ${({ active }) => (active ? '#fff' : '#028CB5')};
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`;

const PlansWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;
`;

const PlanContainer = styled.div<{ isPopular?: boolean }>`
    background-color: #fff;
    border: ${({ isPopular }) =>
            isPopular ? `2px solid #028CB5` : '1px solid #e4e2df'};
    border-radius: 8px;
    box-shadow: ${({ isPopular }) =>
            isPopular ? '0 0 10px rgba(0, 0, 0, 0.2)' : '0 4px 8px rgba(0, 0, 0, 0.1)'};
    width: 100%;
    max-width: 320px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

const PlanTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
`;

const PlanPrice = styled.div`
    font-size: 2rem;
    color: #4BACC9;
    font-weight: bold;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: flex-end;
`;

const FeatureList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 1rem 0;
    flex-grow: 1;
`;

const FeatureItem = styled.li`
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #333333;
    margin-bottom: 0.75rem;

    &::before {
        content: '✓';
        margin-right: 0.5rem;
        color: #028CB5;
    }
`;

const Tag = styled.div`
    position: absolute;
    top: -1rem;
    background-color: #028CB5;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 12px;
`;

const PlanPriceMonth = styled.p`
    font-size: 1rem;
`;

const dict = {
  month: '/month',
  freePlan: {
    name: 'Free Plan',
    price: '0 €',
    features: [
      '5 generations per week',
      '1 posts by week',
      'Unlimited scheduling',
      'Unlimited sources',
    ],
  },
  advancedPlan: {
    name: 'Advanced Plan',
    price: '10 €',
    features: [
      'Unlimited generations',
      'Unlimited posts',
      'Unlimited scheduling',
      'Unlimited sources',
    ],
  },
};


const PricingPage = () => {
  const { myToken } = useContext(AuthContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [isYearly, setIsYearly] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState<ProductModelApi[]>([]);
  const navigate = useNavigate();
  
  
  useEffect(() => {
    window.createLemonSqueezy();
  }, []);
  
  useEffect(() => {
    SubscriptionService.getProductsSubscription(myToken)
      .then(plans => setSubscriptionPlans(plans));
  }, []);
  
  const handleToggle = (yearly: boolean) => {
    setIsYearly(yearly);
  };
  
  const getPrice = (price: string) => {
    const numericPrice = parseFloat(price.replace(',', '.').replace('€', ''));
    return isYearly ? `${(numericPrice * 0.8).toFixed(0)} €` : price;
  };
  
  const getCheckout = (planIndex: number) => {
    if (!subscriptionPlans) {
      messageApi.open({
        type: 'error',
        content: 'Unexpected error while subscribing',
      });
      return;
    }
    const plan = subscriptionPlans[planIndex];
    const variant = isYearly
      ? plan.variants.reduce((max, item) => (item.unitPrice > max.unitPrice ? item : max), plan.variants[0])
      : plan.variants.reduce((min, item) => (item.unitPrice < min.unitPrice ? item : min), plan.variants[0]);
    return SubscriptionService.getCheckoutUrl(myToken, variant.variantId)
      .then(checkoutUrl => {
        window.LemonSqueezy.Url.Open(checkoutUrl);
      });
  };
  
  return (
    <Container id="pricing">
      <Title>Our Pricing Plans</Title>
      <Description>Choose the subscription that best meets your needs.</Description>
      <ToggleWrapper>
        <ToggleButton active={!isYearly} onClick={() => handleToggle(false)}>
          Mensuel
        </ToggleButton>
        <ToggleButton active={isYearly} onClick={() => handleToggle(true)}>
          Annuel (-20%)
        </ToggleButton>
      </ToggleWrapper>
      <Divider />
      <PlansWrapper>
        <PlanContainer isPopular={true}>
          <PlanTitle>{dict.freePlan.name}</PlanTitle>
          <PlanPrice>{getPrice(dict.freePlan.price)}<PlanPriceMonth>{dict.month}</PlanPriceMonth></PlanPrice>
          <FeatureList>
            {dict.freePlan.features.map((feature: string, i: number) => (
              <FeatureItem key={i}>{feature}</FeatureItem>
            ))}
          </FeatureList>
          <Button variant={ButtonVariantEnum.PRIMARY}
                  onClick={() => navigate('/')}>
            Continue with free plan
          </Button>
        </PlanContainer>
        <PlanContainer isPopular={true}>
          <Tag>
            <span>🚀 Popular</span>
          </Tag>
          <PlanTitle>{dict.advancedPlan.name}</PlanTitle>
          <PlanPrice>{getPrice(dict.advancedPlan.price)}<PlanPriceMonth>{dict.month}</PlanPriceMonth></PlanPrice>
          <FeatureList>
            {dict.advancedPlan.features.map((feature: string, i: number) => (
              <FeatureItem key={i}>{feature}</FeatureItem>
            ))}
          </FeatureList>
          <Button variant={ButtonVariantEnum.PRIMARY}
                  onClick={() => getCheckout(0)}>
            Start Now
          </Button>
        </PlanContainer>
      </PlansWrapper>
    </Container>
  );
};

export default PricingPage;