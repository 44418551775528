import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { message, Popconfirm } from 'antd';
import { SubscriptionModelApi } from '../../model/SubscriptionModelApi';
import { SubscriptionService } from '../../service/subscription.service';
import { AuthContext } from '../../auth/AuthContext';
import axios from 'axios';

interface SubscriptionPanelProps {
  authUser: any;
  subscription: SubscriptionModelApi;
}

export const SubscriptionPanel: FC<SubscriptionPanelProps> = ({ authUser, subscription }) => {
  const { myToken } = useContext(AuthContext);
  
  const [localSubscription, setLocalSubscription] = useState(subscription);
  
  const formatedDate = (date: Date | null) => {
    if (!date) {
      return 'never';
    }
    return new Date(date).toLocaleDateString('en-EN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  
  // const toggleSubscriptionStatus = () => {
  //   const newStatus =
  //     localSubscription.status === 'active' || localSubscription.status === 'on_trial'
  //       ? 'cancelled'
  //       : 'active';
  //   setLocalSubscription({ ...localSubscription, status: newStatus });
  // };
  
  const toggleSubscriptionStatus = async () => {
    const newStatus =
      localSubscription.status === 'active' || localSubscription.status === 'on_trial'
        ? 'cancelled'
        : 'active';
    
    const apiUrl =
      newStatus === 'cancelled'
        ? `${process.env.REACT_APP_API_URL}/subscription/cancel`
        : `${process.env.REACT_APP_API_URL}/subscription/resume`;
    
    try {
      // Envoyer la requête PATCH
      const response = await axios.patch(
        apiUrl,
        { subscriptionId: localSubscription.id },
        {
          headers: {
            Authorization: `Bearer ${myToken}`,
          },
        },
      );
      
      if (response.status === 200) {
        message.success(`Subscription successfully ${newStatus === 'cancelled' ? 'cancelled' : 'active'}.`);
        setLocalSubscription({ ...localSubscription, status: newStatus });
      } else {
        throw new Error('Failed to update subscription.');
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
      message.error('Unable to update subscription. Please try again later.');
    }
  };
  
  const updatePaymentMethod = async () => {
    const updatePaymentMethodUrl = await SubscriptionService.getUpdatePaymentMethodUrl(myToken);
    window.LemonSqueezy.Url.Open(updatePaymentMethodUrl);
  };
  
  return (
    <SubscriptionPanelContainer>
      <PanelContainer>
        <CurrentSubscription>
          <Label>Your subscription</Label>
          <CurrentSubscriptionContainer>
            {localSubscription.productName}
          </CurrentSubscriptionContainer>
        </CurrentSubscription>
        <LabelContainer>
          <Label>Automatic renewal</Label>
          <Label>
            {localSubscription.status === 'active' || localSubscription.status === 'on_trial'
              ? 'Active'
              : 'Deactivated'}
            <Popconfirm
              title={`Are you sure you want to ${
                localSubscription.status === 'active' || localSubscription.status === 'on_trial'
                  ? 'cancel'
                  : 'resume'
              } your subscription?`}
              onConfirm={toggleSubscriptionStatus}
              onCancel={() => message.error('Action cancelled')}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                {localSubscription.status === 'active' || localSubscription.status === 'on_trial'
                  ? 'Cancel'
                  : 'Resume'}
              </Button>
            </Popconfirm>
          </Label>
        </LabelContainer>
        {(localSubscription.status === 'on_trial' || localSubscription.status === 'active') && localSubscription.renewsAt && (
          <LabelContainer>
            <Label>Renews at</Label>
            <Label>{formatedDate(localSubscription.renewsAt)}</Label>
          </LabelContainer>
        )}
        {localSubscription.status === 'cancelled' && localSubscription.endsAt && (
          <LabelContainer>
            <Label>Ends at</Label>
            <Label>{formatedDate(localSubscription.endsAt)}</Label>
          </LabelContainer>
        )}
        <LabelContainer>
          <Label>Payment method</Label>
          <Label>
            {localSubscription.cardBrand} *****{localSubscription.cardLastFour}{' '}
            <Button onClick={updatePaymentMethod}>Change payment method</Button>
          </Label>
        </LabelContainer>
      </PanelContainer>
    </SubscriptionPanelContainer>
  );
};

const SubscriptionPanelContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: start;
    margin: 0 40px;
`;

const Label = styled.span`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 50%;
`;

const Button = styled.a`
    color: #028CB5;
    padding-left: 10px;
    cursor: pointer;

    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease, background-color 0.3s ease;

    &:hover {
        color: #0056b3;
        text-decoration: underline;
    }

    &:focus {
        outline: 2px solid #ffbf47;
        outline-offset: 4px;
        border-radius: 4px;
    }
`;

const PanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #ffffff;
    border: 1px solid #e4e2df;
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    margin-right: 50px;

    & ${Label}:nth-child(2) {
        padding-left: 20px;
    }
`;


const CurrentSubscription = styled.div`
    display: flex;
    padding-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const CurrentSubscriptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 5rem;
    width: 50%;
    min-width: 150px;
    border-radius: 10px;
    border: 1px solid #e4e2df;
    color: #727272;

    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;
