import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Switch } from 'antd';
import { Header } from './Header';
import { Footer } from '../CreatePost/Footer';
import Button, { ButtonVariantEnum } from '../buttons/Button';
import UploadOrTemplate from '../CreatePost/UploadOrTemplate/UploadOrTemplate';

// const { Dragger } = Upload;

const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;


const PostImage = styled.img`
    width: 100%;
`;

const Space = styled.div`
    width: calc(100% - 30px);
    height: 33px;
    margin: 0 15px;
    margin-top: 10px;
    border-bottom: 1px solid #e4e2df;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    gap: 3px;
`;

const SwitchStyled = styled(Switch)`
    width: 44px;
    height: 22px;
    position: absolute;
    top: 10px;
    right: 10px;
`;

const PostText = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 12px 16px 30px 16px;
    position: relative;
`;


const StyledTextArea = styled.textarea`
    flex-grow: 1;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    font-family: apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', 'Fira Sans', Ubuntu, Oxygen, 'Oxygen Sans', Cantarell,
    'Droid Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Lucida Grande', Helvetica, Arial, sans-serif;
    color: rgba(0, 0, 0, 0.8);
    line-height: 20px;
    border: 1px solid #e4e2df;
    border-radius: 8px;
    resize: none;
    overflow-y: auto;
    transition: border 0.2s ease;

    // &:hover {
    //   border: 1px solid #e4e2df;
    // }

    &:focus {
        outline: none;
    }

    &::-webkit-scrollbar {
        height: 7px;
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 5px;
        background: rgba(200, 200, 200, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: #aaa;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #888;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }
`;


interface PreviewProps {
  image: string | null;
  setImageUpload: (image: any) => void;
  setImageUrl: (image: any) => void;
  contentPost: string;
  setContentPost: (text: string) => void;
  setHtmlContent: (html: any) => void;
  template: any | null;
  setTemplate: (template: any) => void;
  OnDeletePost: () => void;
  setTemplatePipe: (template: any) => void;
  templateContent: any;
  onUpdate: () => void;
  onSend: () => void;
  ref?: React.Ref<HTMLDivElement>;
}

const Preview = forwardRef<HTMLDivElement, PreviewProps>((props, ref) => {
  const {
    templateContent,
    setTemplatePipe,
    template,
    setTemplate,
    image,
    setImageUpload,
    setImageUrl,
    contentPost,
    setContentPost,
    setHtmlContent,
    OnDeletePost,
    onUpdate,
    onSend,
  } = props;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [showFooter, setShowFooter] = useState(true);
  
  useEffect(() => {
    const showLinkedinFooter = localStorage.getItem('showLinkedinFooter') == 'true';
    setShowFooter(showLinkedinFooter);
  }, []);
  
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [contentPost]);
  
  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContentPost(e.target.value);
  };
  
  return (
    <PreviewContainer ref={ref}>
      <Header onDelete={OnDeletePost} onSave={onUpdate} onSend={onSend} />
      <PostText>
        <StyledTextArea
          ref={textAreaRef}
          value={contentPost}
          onChange={handleContentChange}
          placeholder="What do you want to talk about?"
          maxLength={8000}
        />
      </PostText>
      
      <UploadOrTemplate
        image={image}
        setImageUpload={setImageUpload}
        setImageUrl={setImageUrl}
        template={template}
        setTemplate={setTemplate}
        setTemplatePipe={setTemplatePipe}
        templateContent={templateContent}
        contentPost={contentPost}
        isTextEmpty={contentPost.trim() === ''}
        setHtmlContent={setHtmlContent}
      />
      {showFooter && (
        <>
          <Space>
            <img src="https://static.licdn.com/aero-v1/sc/h/8ekq8gho1ruaf8i7f86vd1ftt" alt="like"
                 data-test-reactions-icon-type="LIKE" data-test-reactions-icon-theme="light" /> 62
          </Space>
          <Footer />
        </>
      )}
    </PreviewContainer>
  );
});

const PreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e4e2df;
    padding: 16px;
`;

export {
  Loader,
  PostText,
  PostImage,
  Space,
  SwitchStyled,
  Preview,
  Button,
  ButtonVariantEnum,
};