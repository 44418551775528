import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input } from 'antd';

interface InputProps {
  value?: string;
  required?: boolean | undefined;
  onChange?: (value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  name: string;
  maxLength?: number | undefined;
  errorMessage?: string | undefined;
  onError?: boolean | undefined;
  setOnError?: (value: boolean) => void;
  resetInput?: boolean | undefined;
}

const InputText: React.FC<InputProps> = ({
                                           value,
                                           onChange,
                                           placeholder,
                                           name,
                                           maxLength,
                                           errorMessage,
                                           required,
                                           onError,
                                           setOnError,
                                           resetInput,
                                         }) => {
  const [touched, setTouched] = useState(false);
  
  useEffect(() => {
    setTouched(false);
    if (setOnError) {
      setOnError(false);
    }
  }, [resetInput]);
  
  useEffect(() => {
    if (setOnError) {
      if (required && touched && value && value.trim() === '') {
        setOnError(true);
      } else {
        setOnError(false);
      }
    }
  }, [required, touched, value]);
  
  return (
    <div>
      <Input
        name={name}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={() => setTouched(true)}
        placeholder={placeholder}
        status={onError ? 'error' : ''}
        maxLength={maxLength}
      />
      {onError &&
        <div style={{
          color: 'red',
          marginTop: 4,
          paddingLeft: '10px',
        }}>{errorMessage ? errorMessage : 'This field is required'}</div>}
    </div>
  );
};

export default InputText;