import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Input, List, Modal, Select, Slider, Tabs, Typography } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Persona } from '../../types';
import InputText from '../../common/Input';
import SelectOption from '../../common/Select';

const { Option } = Select;
const { TabPane } = Tabs;
const { Title } = Typography;

interface PersonaModalProps {
  visible: boolean;
  onCreateOrEdit: (persona: Persona) => void;
  persona: Persona | null;
  onCancel: () => void;
}

const FormItem = styled.div`
    margin-bottom: 20px;
`;

const initialFormState: Persona = {
  id: undefined,
  type: undefined,
  name: undefined,
  description: '',
  targetAudience: '',
  toneOfVoice: undefined,
  language: undefined,
  postLengthPreference: undefined,
  emoticonUsage: 5,
  postExamples: [],
};

const PersonaModal: React.FC<PersonaModalProps> = ({ visible, onCreateOrEdit, persona, onCancel }) => {
  const [form, setForm] = useState<Persona>(initialFormState);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [resetModal, setResetModal] = useState<boolean>(false);
  const [personaNameInputError, setPersonaNameInputError] = useState<boolean>(false);
  const [toneOfCommunicationSelectError, setToneOfCommunicationSelectError] = useState<boolean>(false);
  const [languageSelectError, setLanguageSelectError] = useState<boolean>(false);
  const [lengthSelectError, setLengthSelectError] = useState<boolean>(false);
  const [personaTypeSelectError, setPersonaTypeSelectError] = useState<boolean>(false);
  const tabs = [
    {
      key: 'whoami',
      validateForm: () => {
        let hasError = true;
        if (!form.name || form.name.trim() === '') {
          setPersonaNameInputError(true);
          hasError = false;
        }
        if (!form.type || form.type.trim() === '') {
          setPersonaTypeSelectError(true);
          hasError = false;
        }
        return hasError;
      },
    },
    {
      key: 'target',
      validateForm: () => {
        return true;
      },
    },
    {
      key: 'communication',
      validateForm: () => {
        let hasError = true;
        if (!form.toneOfVoice || form.toneOfVoice.trim() === '') {
          setToneOfCommunicationSelectError(true);
          hasError = false;
        }
        if (!form.language || form.language.trim() === '') {
          setLanguageSelectError(true);
          hasError = false;
        }
        if (!form.postLengthPreference || form.postLengthPreference.trim() === '') {
          setLengthSelectError(true);
          hasError = false;
        }
        return hasError;
      },
    },
    {
      key: 'examples',
      validateForm: () => {
        return true;
      },
    },
  ];
  
  useEffect(() => {
    if (persona) {
      setForm(persona);
    } else {
      setForm(initialFormState);
    }
  }, [persona]);
  
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  
  const handleLanguageChange = (e: string) => {
    setForm({
      ...form,
      language: e,
    });
  };
  
  const handleSelectChange = (value: any, field: keyof Persona) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  
  const handleNumberChange = (value: number | null, field: keyof Persona) => {
    if (value !== null) {
      setForm({
        ...form,
        [field]: value,
      });
    }
  };
  
  const handleTypeChange = (value: 'individual' | 'company') => {
    setForm({
      ...form,
      type: value,
    });
  };
  
  const addPostExample = () => {
    if (form.postExamples.length >= 5) return;
    setForm({
      ...form,
      postExamples: [...form.postExamples, ''],
    });
  };
  
  const updatePostExample = (value: string, index: number) => {
    const newPostExamples = [...form.postExamples];
    newPostExamples[index] = value;
    setForm({
      ...form,
      postExamples: newPostExamples,
    });
  };
  
  const removePostExample = (index: number) => {
    const newPostExamples = [...form.postExamples];
    newPostExamples.splice(index, 1);
    setForm({
      ...form,
      postExamples: newPostExamples,
    });
  };
  
  const handleSubmit = () => {
    // Validation du formulaire si nécessaire
    onCreateOrEdit(form);
    setResetModal(true);
    setCurrentTab(0);
  };
  
  const handlePrev = () => {
    setCurrentTab(Math.max(0, currentTab - 1));
  };
  
  const handleCancel = () => {
    setCurrentTab(0);
    setResetModal(true);
    onCancel();
  };
  
  const handleNext = () => {
    if (tabs[currentTab].validateForm()) {
      setCurrentTab(Math.min(tabs.length - 1, currentTab + 1));
    }
  };
  
  const resetModalAndForm = () => {
    setForm(persona || initialFormState);
    setResetModal(!resetModal);
  };
  
  return (
    <Modal
      title={persona ? 'Edit Persona' : 'Create a new Persona'}
      open={visible}
      onCancel={handleCancel}
      maskClosable={false}
      width={800}
      footer={null}
      afterOpenChange={() => resetModalAndForm()}
    >
      <Tabs activeKey={tabs[currentTab].key} defaultActiveKey="whoami">
        <TabPane tab="Who I Am" key={tabs[0].key}>
          <FormItem>
            <Title level={5}>Persona Type</Title>
            <SelectOption
              value={form.type}
              onChange={handleTypeChange}
              placeholder="Select the persona type"
              style={{ width: '100%' }}
              setOnError={setPersonaTypeSelectError}
              onError={personaTypeSelectError}
              resetInput={resetModal}
              options={[
                { value: 'individual', label: 'Individual' },
                { value: 'company', label: 'Company' },
              ]}
            />
          </FormItem>
          <FormItem>
            <Title level={5}>Persona name</Title>
            <InputText
              name="name"
              value={form.name}
              onChange={handleInputChange}
              required={true}
              placeholder={form.type === 'individual' ? 'Name' : 'Company Name'}
              maxLength={200}
              errorMessage={'Persona name is required'}
              onError={personaNameInputError}
              setOnError={setPersonaNameInputError}
              resetInput={resetModal}
            />
          </FormItem>
          <FormItem>
            <Title level={5}>Tone of Communication</Title>
            <Input.TextArea
              name="description"
              value={form.description}
              onChange={handleInputChange}
              placeholder="Description (optional)"
              rows={4}
              maxLength={2000}
            />
          </FormItem>
        </TabPane>
        <TabPane tab="Target Audience" key={tabs[1].key}>
          <FormItem>
            <Input.TextArea
              name="targetAudience"
              value={form.targetAudience}
              onChange={handleInputChange}
              placeholder="Description of the target audience"
              rows={4}
              maxLength={2000}
            />
          </FormItem>
        </TabPane>
        <TabPane tab="Communication Style" key={tabs[2].key}>
          <FormItem>
            <Title level={5}>Tone of Communication *</Title>
            <SelectOption
              value={form.toneOfVoice}
              onChange={(value) => handleSelectChange(value, 'toneOfVoice')}
              placeholder="Select a tone of communication"
              style={{ width: '100%' }}
              setOnError={setToneOfCommunicationSelectError}
              onError={toneOfCommunicationSelectError}
              resetInput={resetModal}
              options={[
                { value: 'Professional', label: 'Professional' },
                { value: 'Casual', label: 'Casual' },
                { value: 'Humorous', label: 'Humorous' },
                { value: 'Inspirational', label: 'Inspirational' },
              ]}
            />
          </FormItem>
          <FormItem>
            <Title level={5}>Language *</Title>
            <SelectOption
              value={form.language}
              onChange={handleLanguageChange}
              placeholder="Select the language"
              style={{ width: '100%' }}
              setOnError={setLanguageSelectError}
              onError={languageSelectError}
              resetInput={resetModal}
              options={[
                { value: 'english', label: 'English' },
                { value: 'french', label: 'French' },
                { value: 'spanish', label: 'Spanish' },
                { value: 'german', label: 'German' },
                { value: 'italian', label: 'Italian' },
                { value: 'portuguese', label: 'Portuguese' },
                { value: 'chinese', label: 'Chinese' },
                { value: 'japanese', label: 'Japanese' },
                { value: 'korean', label: 'Korean' },
                { value: 'russian', label: 'Russian' },
                { value: 'arabic', label: 'Arabic' },
                { value: 'hindi', label: 'Hindi' },
                { value: 'bengali', label: 'Bengali' },
                { value: 'urdu', label: 'Urdu' },
                { value: 'swahili', label: 'Swahili' },
                { value: 'turkish', label: 'Turkish' },
                { value: 'vietnamese', label: 'Vietnamese' },
                { value: 'thai', label: 'Thai' },
                { value: 'dutch', label: 'Dutch' },
                { value: 'greek', label: 'Greek' },
                { value: 'polish', label: 'Polish' },
                { value: 'swedish', label: 'Swedish' },
                { value: 'norwegian', label: 'Norwegian' },
                { value: 'finnish', label: 'Finnish' },
                { value: 'danish', label: 'Danish' },
                { value: 'hebrew', label: 'Hebrew' }]}
            />
          </FormItem>
          <FormItem>
            <Title level={5}>Preference for Post Length *</Title>
            <SelectOption
              value={form.postLengthPreference}
              onChange={(value) => handleSelectChange(value, 'postLengthPreference')}
              placeholder="Preference for post length"
              style={{ width: '100%' }}
              setOnError={setLengthSelectError}
              onError={lengthSelectError}
              resetInput={resetModal}
              options={[
                { value: 'short', label: 'Short' },
                { value: 'medium', label: 'Medium' },
                { value: 'long', label: 'Long' },
              ]}
            />
          </FormItem>
          <FormItem>
            <Title level={5}>Emoticon Usage</Title>
            <Slider
              value={form.emoticonUsage}
              min={0}
              max={10}
              onChange={(value) => handleNumberChange(value, 'emoticonUsage')}
              marks={{
                0: '0',
                2: '2',
                4: '4',
                6: '6',
                8: '8',
                10: '10',
              }}
            />
          </FormItem>
        </TabPane>
        <TabPane tab="Post Examples" key={tabs[3].key}>
          <Title level={5}>Get inspiration from posts examples (optional)</Title>
          <FormItem>
            <Button
              type="dashed"
              onClick={addPostExample}
              style={{ width: '100%', marginBottom: '20px' }}
            >
              <PlusOutlined /> Add a post example
            </Button>
            <List
              dataSource={form.postExamples}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => removePostExample(index)}
                    />,
                  ]}
                >
                  <Input.TextArea
                    value={item}
                    onChange={(e) => updatePostExample(e.target.value, index)}
                    placeholder={`Post example ${index + 1}`}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    maxLength={8000}
                  />
                </List.Item>
              )}
            />
          </FormItem>
        </TabPane>
      </Tabs>
      <div style={{ textAlign: 'right' }}>
        <Button style={{ marginRight: 8 }} onClick={handleCancel}>
          Cancel
        </Button>
        {currentTab > 0 && (
          <Button style={{ marginRight: 8 }} onClick={handlePrev}>
            Précédent
          </Button>
        )}
        
        {currentTab < tabs.length - 1 && (
          <Button type="primary" onClick={handleNext}>
            Suivant
          </Button>
        )}
        
        {currentTab === tabs.length - 1 && (
          <Button type="primary" onClick={handleSubmit}>
            Confirmer
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default PersonaModal;
