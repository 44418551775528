import React, { useContext, useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import styled from 'styled-components';
import { Persona } from '../../types';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AuthContext } from '../../auth/AuthContext';
import PersonaModal from './PersonaModal';

const PageContainer = styled.div`
    padding: 20px;
`;

const CardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

const PersonaCard = styled(Card)`
    width: 300px;
    flex: 0 0 auto;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    .ant-card-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
`;

const ContentContainer = styled.div`
    flex: 1;
`;

const ContentParagraph = styled.p`
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Adjust the number of lines as needed */
    -webkit-box-orient: vertical;
`;

const AddCard = styled.div`
    border: 1px solid rgb(240, 240, 240);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 358px;
    background-color: #fff;
    font-size: 36px;
    color: #c3c3c3;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

    &:hover {
        background-color: #f8f8f8;
        color: rgb(2, 140, 181);
        border: 1px solid rgb(2, 140, 181);
    }
`;

const PersonaList: React.FC = () => {
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPersona, setCurrentPersona] = useState<Persona | null>(null);
  const { myToken } = useContext(AuthContext);
  
  useEffect(() => {
    const fetchPersonas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/personas/user`, {
          headers: {
            Authorization: `Bearer ${myToken}`,
          },
        });
        setPersonas(response.data);
      } catch (error) {
        console.error('Error fetching personas:', error);
      }
    };
    
    fetchPersonas();
  }, [myToken]);
  
  const handleCreateOrEdit = async (persona: Persona) => {
    try {
      if (currentPersona) {
        // Mise à jour du persona existant
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/personas/${persona.id}`, persona, {
          headers: {
            Authorization: `Bearer ${myToken}`,
          },
        });
        setPersonas(personas.map(p => (p.id === persona.id ? response.data : p)));
      } else {
        // Création d'un nouveau persona
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/personas`, persona, {
          headers: {
            Authorization: `Bearer ${myToken}`,
          },
        });
        setPersonas([...personas, response.data]);
      }
      setModalVisible(false);
      setCurrentPersona(null);
    } catch (error) {
      console.error('Error saving persona:', error);
    }
  };
  
  const handleEditClick = (persona: Persona) => {
    setCurrentPersona(persona);
    setModalVisible(true);
  };
  
  const handleDeleteClick = async (personaId: string) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/personas/${personaId}`, {
        headers: {
          Authorization: `Bearer ${myToken}`,
        },
      });
      setPersonas(personas.filter((p: any) => p.id !== personaId));
    } catch (error) {
      console.error('Error deleting persona:', error);
    }
  };
  
  return (
    <PageContainer>
      <CardContainer>
        <AddCard onClick={() => setModalVisible(true)}>
          <PlusOutlined />
        </AddCard>
        {personas.map(persona => (
          <PersonaCard
            key={persona.id}
            title={persona.name}
            actions={[
              <Button onClick={() => handleEditClick(persona)}>Edit</Button>,
              <Button danger onClick={() => handleDeleteClick(persona.id!)}>
                Delete
              </Button>,
            ]}
          >
            <ContentContainer>
              <ContentParagraph>
                <strong>Type:</strong> {persona.type === 'individual' ? 'Individual' : 'Company'}
              </ContentParagraph>
              <ContentParagraph>
                <strong>Description:</strong> {persona.description}
              </ContentParagraph>
              <ContentParagraph>
                <strong>Target Audience:</strong> {persona.targetAudience}
              </ContentParagraph>
              <ContentParagraph>
                <strong>Tone of Voice:</strong> {persona.toneOfVoice}
              </ContentParagraph>
              <ContentParagraph>
                <strong>Language:</strong> {persona.language}
              </ContentParagraph>
              <ContentParagraph>
                <strong>Post Length Preference:</strong> {persona.postLengthPreference}
              </ContentParagraph>
              <ContentParagraph>
                <strong>Emoticon Usage:</strong> {persona.emoticonUsage}
              </ContentParagraph>
            </ContentContainer>
          </PersonaCard>
        ))}
      </CardContainer>
      <PersonaModal
        visible={modalVisible}
        onCreateOrEdit={handleCreateOrEdit}
        persona={currentPersona}
        onCancel={() => {
          setModalVisible(false);
          setCurrentPersona(null);
        }}
      />
    </PageContainer>
  );
};

export default PersonaList;
