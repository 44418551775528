import React, { useContext } from 'react';
import { Button, Form, Input, message as antdMessage, Rate } from 'antd';
import styled from 'styled-components';
import { AuthContext } from '../auth/AuthContext';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        align-items: flex-start;
        padding: 20px;
        height: 100%;
    }
`;

const ContactPageWrapper = styled.div`
    width: 600px;
    min-width: 300px;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e4e2df;
`;

const Title = styled.h2`
    text-align: center;
    margin-bottom: 1rem;
    color: #028cb5;
`;

const Mail = styled.p`
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
    color: #c2c2c2;
`;

const ContactPage: React.FC = () => {
  const [form] = Form.useForm();
  const { myToken } = useContext(AuthContext);
  
  const onFinish = async (values: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/opinions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myToken}`,
          'accept': 'application/json',
        },
        body: JSON.stringify({
          message: values.message,
          rating: values.rating || 0,
        }),
      });
      
      if (response.ok) {
        antdMessage.success('Thank you for your feedback!');
        form.resetFields();
      } else {
        antdMessage.error('Something went wrong. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      antdMessage.error('Network error. Please try again later.');
    }
  };
  
  const onFinishFailed = (errorInfo: any) => {
    antdMessage.error('Please fill out all required fields.');
  };
  
  return (
    <Container>
      <ContactPageWrapper>
        <Title>Contact Us 🤝</Title>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Message"
            name="message"
            rules={[{ required: true, message: 'Please enter your message.' }]}
          >
            <Input.TextArea rows={6} placeholder="Let us know what you think about PostPilot 💪" />
          </Form.Item>
          
          <Form.Item label="Rating" name="rating">
            <Rate />
          </Form.Item>
          
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit 🚀
            </Button>
          </Form.Item>
        </Form>
        <Mail>
          or email us at <a href="mailto:contact@postpilot.in">contact@postpilot.in</a>
        </Mail>
      </ContactPageWrapper>
    </Container>
  );
};

export default ContactPage;
